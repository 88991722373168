import styled from "styled-components"

export const StyledHorizontalSlider = styled.section`
  font-family: ${props => props.theme.fonts.primary};
  display:flex;
  flex-wrap:wrap;
  flex-direction:column;

  @media ${props => props.theme.sizes.laptop} {
    height:100vh;
  }


  .slider {
    height:100%;
    display:flex;
    flex-direction: column;
    background: #fff;

    @media ${props => props.theme.sizes.laptop} {
      width: 100vw;
      padding-top: 10vh;
      flex-direction: row;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100vw;
      padding: 0 5vw;

      @media ${props => props.theme.sizes.laptop} {
        width: 80%;
        padding: 0 4%;
      }
      @media ${props => props.theme.sizes.desktop} {
        width: 50%;
        padding: 0 10%;
      }

      ul {
        list-style: none;
      }
      p,ul {
        @media ${props => props.theme.sizes.laptop} {
          font-size: 0.85rem;
        }
        @media ${props => props.theme.sizes.desktop} {
          font-size: 1rem;
        }
      }
    }
    &__title {
      font-size: 30px;
      color: ${props => props.theme.colors.text};
      font-weight: 400;

      @media ${props => props.theme.sizes.laptopL} {
        font-size: 40px;
      }
      @media ${props => props.theme.sizes.desktop} {
        font-size: 60px;
      }

      .bold {
        font-weight: 700;
      }
    }
    &__image {
      display: flex;
      margin: 0;
      width: 100vw;

      @media ${props => props.theme.sizes.laptop} {
        width: 50%;
      }
    }
  }
`