import React, { useEffect, useState, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Container from "../../container"
import { StyledConecptAdventages, StyledSingle} from "./StyledElements/StyledConceptAdventages"


const ConecptAdventages = props => {

    gsap.registerPlugin(ScrollTrigger);

    const revealRef = useRef([])
    revealRef.current = []


    const revealRefs = useRef([]);
    revealRefs.current = [];

    useEffect(() => {

            revealRefs.current.forEach((el, index) => {

              gsap.fromTo(el, {
                autoAlpha: 0
              }, {
                duration: 1.5,
                autoAlpha: 1,
                ease: 'none',
                scrollTrigger: {
                  id: `section-${index+1}`,
                  trigger: el,
                  start: 'top+=50 center+=100',
                  end: 'center+=80 center+=50',
                //   toggleActions: 'play none none reverse',
                    scrub: true,
                }
              });

            });

    }, []);

    const addToRefs = el => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

  const elements = props.data;

  return (
    <StyledConecptAdventages className="adventages">
        <Container>
            <div className="adventages__wrapper">
                <div className="adventages__title">
                    <h3 className="adventages__title--text">{props.fisrtSection}</h3>
                </div>
                <div className="adventages__elements">
                    {elements.map((item, index) =>
                    { if (index <= 3)
                      return  (
                            <StyledSingle className="singleElement" key={index} ref={addToRefs}>
                                <div className="list"
                                    dangerouslySetInnerHTML={{
                                    __html: item.single,
                                    }}
                                />
                            </StyledSingle>
                        )
                    })}
                </div>
            </div>
            <div className="adventages__wrapper">
                <div className="adventages__title">
                    <h3 className="adventages__title--text">{props.secondSection}</h3>
                </div>
                <div className="adventages__elements">
                    {elements.map((item, index) =>
                    { if (index >= 4)
                        return  (
                              <StyledSingle className="singleElement" key={index} ref={addToRefs}>
                                  <div className="list"
                                      dangerouslySetInnerHTML={{
                                      __html: item.single,
                                      }}
                                  />
                              </StyledSingle>
                          )
                      })}
                </div>
            </div>
        </Container>
    </StyledConecptAdventages>
  )
}

export default ConecptAdventages
