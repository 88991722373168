import styled from "styled-components"

export const StyledSmallTitle = styled.section`
z-index: 0;
position: relative;
padding: 10vh 0;
background: #fff;

    @media ${props => props.theme.sizes.laptop} {
        padding: 10vh 0;
    }

h2 {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-size: clamp(50px,19vmin,70px);
    gap: 30px;
    overflow: hidden;
    word-spacing: 10px;

    @media ${props => props.theme.sizes.laptop} {
        font-size: clamp(130px,19vmin,150px);
        gap: 50px;
    }
}
`
export const StyledFullTitle = styled.div`
    display: flex;
    flex-direction: row;
    gap: 100px;
    width: max-content;
    transform: translateX(${props => props.transalteStart});
    animation: 35s infinite alternate ${props => props.keyFrame};
    animation-timing-function: linear;

    @keyframes ${props => props.keyFrame} {
        from {
            transform: translateX(${props => props.transalteStart});
        }

        to {
            transform: translateX(${props => props.transalteEnd});
        }
    }

`
export const StyledTransparentTitle = styled(StyledFullTitle)`
    width: max-content;
    -webkit-text-fill-color: transparent;
    -moz-text-stroke-color: ${props => props.theme.colors.primary};
    -webkit-text-stroke-color: ${props => props.theme.colors.primary};
    -moz-text-stroke-width: 2px;
    -webkit-text-stroke-width: 2px;
`
export const StyledFloatingLine = styled.h3`
    width: max-content;
    font-size: clamp(50px,19vmin,100px);
    gap: 90px;
    overflow: hidden;
    word-spacing: 10px;
    display: flex;
    flex-direction: row;
    color: ${props => props.theme.colors.black};
    transform: translateX(${props => props.transalteStart});
    animation: 35s infinite alternate ${props => props.keyFrame};
    animation-timing-function: linear;
    overflow: hidden;

    p {
        font-weight: 500;
    }
    strong {
        font-weight: 700;
    }

    @keyframes ${props => props.keyFrame} {
        from {
            transform: translateX(${props => props.transalteStart});
        }

        to {
            transform: translateX(${props => props.transalteEnd});
        }
    }

`