import React, { useEffect, useState, useRef } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StyledHorizontalSlider } from "./StyledElements/StyledHorizontalSlider"

import gsap from "gsap"


const HorizontalSlider = props => {

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
      if (window.innerWidth > 1024) {
        let sections = gsap.utils.toArray(".section");
        let maxWidth = 0;

        const getMaxWidth = () => {
        maxWidth = 0;
        sections.forEach((section) => {
            maxWidth += section.offsetWidth;
        });
        };
        getMaxWidth();


        gsap.to(sections, {
          x: () => `-${maxWidth - window.innerWidth}`,
          ease: "none",
          scrollTrigger: {
              trigger: "#SliderElement",
              pin: true,
              scrub: true,
              end: () => `+=${maxWidth}`,
              invalidateOnRefresh: true
          }
        });
      } else {
        revealRefs.current.forEach((el, index) => {

          gsap.fromTo(el, {
            yPercent: 0
          }, {
            duration: 7.5,
            yPercent: 100,
            ease: 'none',
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
              start: 'center+=300 center-=100',
              end: '200%',
              toggleActions: 'play none none reverse',
              scrub: true
            }
          });

        });
      }
  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  const slides = props.data;

  return (
    <StyledHorizontalSlider id="SliderElement" >

        {slides.map((item, index) => (
            <section className="module section slider" key={index} ref={addToRefs}>
                <div className="slider__content" >
                  <h4 className="slider__title">
                    <span>{item.title.split('_')[0]}_</span>
                    <span className="bold">{item.title.split('_')[1]}</span>
                  </h4>
                  <div className="slider__text"
                      dangerouslySetInnerHTML={{
                      __html: item.content,
                      }}
                  />
                </div>
                <figure className="slider__image">
                  <GatsbyImage
                      image={getImage(item.image.localFile)}
                      alt={item.image.altText}
                      title={item.image.title}
                  />
                </figure>
            </section>
        ))}

    </StyledHorizontalSlider>
  )
}

export default HorizontalSlider
