import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { StyledBigTitle, StyledFullTitle, StyledTransparentTitle } from "./StyledElements/StyledBigTitle"

const BigTitle = props => {

  let TitleFirstRow = props.title.split(' ')[0];
  let TitleSecondRow = props.title.split(' ')[1];
  let TitleThirdRow = props.title.split(' ')[2];

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
      revealRefs.current.forEach((el, index) => {

        gsap.fromTo(el, {
          yPercent: 0
        }, {
          duration: 7.5,
          yPercent: 100,
          ease: 'none',
          scrollTrigger: {
            id: `section-${index+1}`,
            trigger: el,
            start: 'top',
            end: '200%',
            toggleActions: 'play none none reverse',
            scrub: true
          }
        });

      });


  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  return (
    <StyledBigTitle ref={addToRefs}>
        <h1 className="bigTitle">
            <StyledFullTitle keyFrame='f_line' transalteStart='-57%' transalteEnd='-7%'>
                <span>{TitleFirstRow}</span>
                <span>{TitleFirstRow}</span>
                <span>{TitleFirstRow}</span>
                <span>{TitleFirstRow}</span>
                <span>{TitleFirstRow}</span>
                <span>{TitleFirstRow}</span>
            </StyledFullTitle>
            <StyledTransparentTitle keyFrame='s_line' transalteStart='-12%' transalteEnd='-27%'>
                <span>{TitleSecondRow}</span>
                <span>{TitleSecondRow}</span>
                <span>{TitleSecondRow}</span>
                <span>{TitleSecondRow}</span>
                <span>{TitleSecondRow}</span>
                <span>{TitleSecondRow}</span>
            </StyledTransparentTitle>
            <StyledFullTitle keyFrame='t_line' transalteStart='-93.5%' transalteEnd='-13.5%'>
                <span>{TitleThirdRow}</span>
                <span>{TitleThirdRow}</span>
                <span>{TitleThirdRow}</span>
                <span>{TitleThirdRow}</span>
                <span>{TitleThirdRow}</span>
                <span>{TitleThirdRow}</span>
            </StyledFullTitle>
        </h1>
    </StyledBigTitle>
  )
}

export default BigTitle
