import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { StyledSmallTitle, StyledFullTitle, StyledTransparentTitle } from "./StyledElements/StyledSmallTitle"

const SmallTitle = props => {


  let TitleFirstRow = props.firstLine;
  let TitleSecondRow = props.secondLine;

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
      revealRefs.current.forEach((el, index) => {

        gsap.fromTo(el, {
          yPercent: 0
        }, {
          duration: 7.5,
          yPercent: 100,
          ease: 'none',
          scrollTrigger: {
            id: `section-${index+1}`,
            trigger: el,
            start: 'top',
            end: '200%',
            toggleActions: 'play none none reverse',
            scrub: true
          }
        });

      });
  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  return (
    <StyledSmallTitle ref={addToRefs}>
        <h2>
            <StyledFullTitle keyFrame='test' transalteStart='-37%' transalteEnd='-7%'>
                <span>{TitleFirstRow}</span>
                <span>{TitleFirstRow}</span>
                <span>{TitleFirstRow}</span>
                <span>{TitleFirstRow}</span>
                <span>{TitleFirstRow}</span>
                <span>{TitleFirstRow}</span>
            </StyledFullTitle>
            <StyledTransparentTitle className='secondRow' keyFrame='test1' transalteStart='-12%' transalteEnd='-37%'>
                <span>{TitleSecondRow}</span>
                <span>{TitleSecondRow}</span>
                <span>{TitleSecondRow}</span>
                <span>{TitleSecondRow}</span>
                <span>{TitleSecondRow}</span>
                <span>{TitleSecondRow}</span>
            </StyledTransparentTitle>
        </h2>
    </StyledSmallTitle>
  )
}

export default SmallTitle
