import styled from "styled-components"

export const StyledConecptSummary = styled.section`
font-family: ${props => props.theme.fonts.primary};
text-align: left;
background: #fff;
overflow: visible;
position: relative;
padding-bottom: 100px;


@media ${props => props.theme.sizes.laptop} {
  padding-bottom: 200px;
}

strong {
  font-weight: 700;
}
.summary {
  &__wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    background: #F1F1F1;
    overflow: visible;

    @media ${props => props.theme.sizes.laptop} {
      max-width: 70%;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${props => props.theme.sizes.laptop} {
      flex-direction: row;
    }

    .sc {
      &__container {
        padding: 5% 5vw;
        z-index: 10;

        @media ${props => props.theme.sizes.laptop} {
          padding: 5% 0 5% 15%;
        }
      }
    }
  }
  &__title {
    font-size: 30px;
    color: ${props => props.theme.colors.text};
    margin-bottom: 55px;

    @media ${props => props.theme.sizes.laptop} {
      font-size: 40px;
    }
  }
  &__text {


    @media ${props => props.theme.sizes.laptop} {
      font-size: 23px;
      max-width: 75ch;
    }
  }
  &__image {
    transform: translate(51%, 5%);
    height: 90%;
    position: relative;

    .gatsby-image-wrapper {
      height: 100%;
      display: none;

      @media ${props => props.theme.sizes.laptop} {
        display: block;
      }
    }
  }
}
`
export const SmokeImage = styled.figure`
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  left: ${props => props.left};
  bottom: ${props => props.bottom};
  width: 100%;
  height: auto;
  z-index: 10;

  img {
    width: 100%;
    height: 100%;
  }
`
export const MoreButton = styled.div`

  font-family: ${props => props.theme.fonts.primary};
  font-size: 30px;
  font-weight: 700;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 20;
  padding: 5% 5vw;

@media ${props => props.theme.sizes.laptop} {
  position: absolute;
  right: -20%;
  bottom: -70px;
  font-size: 47px;
  padding: 0;
}

a {
  color: ${props => props.theme.colors.text};
  text-decoration: none;
}

`
export const QustionMark = styled.span`
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  left: ${props => props.left};
  bottom: ${props => props.bottom};
  color: ${props => props.color};
  font-size: 200px;
  font-weight: 700;
  font-family: ${props => props.theme.fonts.primary};
  transform: ${props => props.rotate};
  z-index: -10;
  opacity: 0.5;

  @media ${props => props.theme.sizes.laptop} {
    z-index: 30;
    opacity: 1;
  }
`