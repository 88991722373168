import React, { useEffect, useState, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Container from "../../container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StyledConecptElements, SmokeImage, StyledSingle } from "./StyledElements/StyledConceptElements"

import SmokeBackground from "../../../images/SmokeAfter.png"

const ConecptElements = props => {


    gsap.registerPlugin(ScrollTrigger)

    const revealRefs = useRef([]);
    revealRefs.current = [];

    useEffect(() => {
            revealRefs.current.forEach((el, index) => {

              gsap.fromTo(el, {
                autoAlpha: 0
              }, {
                duration: 1.5,
                autoAlpha: 1,
                ease: 'none',
                scrollTrigger: {
                  id: `section-${index+1}`,
                  trigger: el,
                  start: 'top center+=100',
                  end: 'center+=80 center+=50',
                  toggleActions: 'play none none reverse',
                    scrub: true
                }
              });

            });
    }, []);

    const addToRefs = el => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

  const elements = props.data;

  return (
    <StyledConecptElements>
        <SmokeImage
            top='-52%'
            right='-1%'
        >
            <img src={SmokeBackground} alt="orange smoke" />
        </SmokeImage>

        <Container>
            <div className="wrapper">
                {elements.map((item, index) => (
                    <StyledSingle className="singleElement" key={index} ref={addToRefs}>
                        <h4 className="title">{item.title}</h4>
                        <div className="text"
                            dangerouslySetInnerHTML={{
                            __html: item.text,
                            }}
                        />
                    </StyledSingle>
                ))}
            </div>
        </Container>
        <SmokeImage
            top='-10%'
            left='-55%'
        >
            <img src={SmokeBackground} alt="orange smoke" />
        </SmokeImage>
    </StyledConecptElements>
  )
}

export default ConecptElements
