import styled from "styled-components"


export const StyledConecptAdventages = styled.section`
font-size: 30px;
font-weight: 600;
font-family: ${props => props.theme.fonts.primary};
text-align: center;
padding: 50px 0;
position: relative;
background: #fff;

    @media ${props => props.theme.sizes.laptop} {
        padding: 100px 0;
    }

.adventages {
    &__wrapper {
        display: flex;
        flex-direction: column;
        padding: 50px 0;

        @media ${props => props.theme.sizes.laptop} {
            flex-direction: row;
        }
    }
    &__title {
        margin: 0 5vw;

        @media ${props => props.theme.sizes.laptop} {
            width: 30%;
            margin: 0 10% 0 0;
        }

        &--text {
            color: ${props => props.theme.colors.text};
            font-size: 35px;
            line-height: 1.3;
            text-align: left;

            @media ${props => props.theme.sizes.laptop} {
                font-size: 50px;
                line-height: 66px;
            }
        }
    }
    &__elements {
        @media ${props => props.theme.sizes.laptop} {
            width: 60%;
        }
    }
}

`

export const StyledSingle = styled.div`
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
    z-index: 1;

    .list {
        font-size: 25px;
        font-weight: 700;
        text-align: left;
    }
`