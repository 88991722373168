import styled from "styled-components"

export const StyledConecptIntro = styled.section`
z-index: 1;
position: relative;
font-size: 42px;
line-height: 1.25;
font-weight: 700;
font-family: ${props => props.theme.fonts.primary};
text-align: left;
padding: 20px 1rem;
background: #fff;

@media ${props => props.theme.sizes.tabletS} {
  font-size: 59px;
  line-height: 1.35;
  padding: 20px 0;
}
@media ${props => props.theme.sizes.laptop} {
  font-size: 65px;
  line-height: 1.55;
  padding: 50px 0;
  min-height: 100vh;
  text-align: center;
}
@media ${props => props.theme.sizes.laptopL} {
  font-size: 75px;
  line-height: 1.55;
  padding: 50px 0;
  min-height: 100vh;
  text-align: center;
}

    ul {
      list-style: none;
    }

    li {
      display: list-item;
      margin-bottom: 0;

      &.is-active {
        color: orange;
      }
    }

    p {
        line-height: 66px;
    }

    strong {
        font-weight: 700;
        color: ${props => props.theme.colors.primary};
    }
`