import styled from "styled-components"

export const StyledConecptElements = styled.section`
    font-size: 30px;
    font-weight: 600;
    font-family: ${props => props.theme.fonts.primary};
    text-align: center;
    padding: 50px 0;
    position: relative;

    .wrapper {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        @media ${props => props.theme.sizes.laptop} {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            grid-auto-flow: row;
            grid-template-areas:
            "first-row first-row first-row first-row . . . . ."
            ". . . second-row second-row second-row second-row second-row second-row"
            "third-row third-row third-row third-row third-row . . . .";
        }
    }
`
export const SmokeImage = styled.figure`
    position: absolute;
    top: ${props => props.top};
    right: ${props => props.right};
    left: ${props => props.left};
    width: 50%;
    display: none;
    z-index: 8;

    @media ${props => props.theme.sizes.laptop} {
        display: block;
        width: 30%;
    }
    @media ${props => props.theme.sizes.desktop} {
        width: 50%;
        display: block;
    }

`
export const StyledSingle = styled.div`
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;

    &:nth-of-type(1) {
        grid-area: first-row;
        border-right: 1px solid #000;
        position: relative;

        &::after {
            content: '';
            width: 250%;
            height: 1px;
            background: #000;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    &:nth-of-type(2) {
        grid-area: second-row;
        border-left: 1px solid #000;
        position: relative;

        &::after {
            content: '';
            width: 250%;
            height: 1px;
            background: #000;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    &:nth-of-type(3) {
        grid-area: third-row;
        border-right: 1px solid #000;
    }


    .title {
        font-size: 40px;
        font-weight: 700;
        font-family: ${props => props.theme.fonts.primary};
        color: #000;
        text-align: left;
    }
    .text {
        font-size: 23px;
        font-weight: 400;
        text-align: left;

        p {
            margin-bottom: 5px;
        }

        ul {
            list-style: none;
            margin: 0;

            li {
                margin-bottom: 5px;
            }
        }
    }
`