import React, { useRef, useEffect } from 'react'

import Layout from "../../components/layout"
import { SEO } from "../../components/seo"

import BigTitle from "../../components/organisms/OfferlandingPages/BigTitle";
import SmallTitle from "../../components/organisms/OfferlandingPages/SmallTitle";
import ConecptIntro from "../../components/organisms/OfferlandingPages/ConceptIntro";
import ConecptElements from "../../components/organisms/OfferlandingPages/ConceptElements";
import HorizontalSlider from "../../components/organisms/OfferlandingPages/HorizontalSlider";
import ConecptAdventages from "../../components/organisms/OfferlandingPages/ConecptAdventages";
import ConecptSummury from "../../components/organisms/OfferlandingPages/ConceptSummary";

const Page = props => {
  const SEO = props.pageContext.seo
  const ACF = props.pageContext.ACF_page_koncepcja

  // const containerRef = useRef(null)
  const scrollRef = React.createRef();

  useEffect(() => {


  });

  return (
    <Layout seoData={SEO} seoUrl={props.pageContext.uri}>
      <div className="scroll" style={{overflow: "hidden"}}>
        <BigTitle
        title={props.pageContext.title}
        />
        <ConecptIntro
        data={ACF.introtextmain}
        />
        <ConecptElements
        data={ACF.pkkElements}
        />
        <HorizontalSlider
        data={ACF.pkkSlider}
        />
        <SmallTitle
        firstLine={ACF.pkkHeaderFl}
        secondLine={ACF.pkkHeaderSl}
        />
        <ConecptAdventages
        data={ACF.adventagesPoints}
        fisrtSection={ACF.adventagesTF}
        secondSection={ACF.adventagesTS}
        />
        <ConecptSummury
        data={ACF.summaryText}
        title={ACF.summaryTitle}
        image={ACF.summaryImage}
        button={ACF.summaryButton}
        />

      </div>
    </Layout>

  )
}
export default Page

export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}
