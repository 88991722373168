import React, { useEffect, useState, useRef } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SmokeBackground from "../../../images/SmokeAfter.png"
import { StyledConecptSummary, SmokeImage, MoreButton, QustionMark} from "./StyledElements/StyledConceptSummary"

import gsap from "gsap"


const ConecptSummary = props => {


  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {

      revealRefs.current.forEach((el, index) => {

        gsap.fromTo(el, {
          yPercent: -10
        }, {
          duration: 2.5,
          yPercent: 10,
          scrollTrigger: {
            id: `section-${index+1}`,
            trigger: el,
            start: 'top center+=50',
            end: 'bottom center+=20',
            toggleActions: 'play none none reverse',
            scrub: true,
          }
        });

      });
  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  return (
    <StyledConecptSummary id="triggerElement" className="summary">
      <div className="summary__wrapper">
          <div className="summary__content">
            <div className="sc__container">
              <h3 className="summary__title">{props.title}</h3>
              <div className="summary__text"
                  dangerouslySetInnerHTML={{
                  __html: props.data,
                  }}
              >
            </div>
            </div>
            <div className="summary__image">
                <QustionMark
                  color= '#FBB9CE'
                  rotate='rotate(-10deg)'
                  left= '-50px'
                  top= '-80px'

                >?</QustionMark>
                <QustionMark
                  color= '#F4B34C'
                  rotate='rotate(25deg)'
                  right= '-50px'
                  top= '30px'

                >?</QustionMark>
                <QustionMark
                  color= '#FBB9CE'
                  rotate='rotate(25deg)'
                  left= '-50px'
                  bottom= '0'

                >?</QustionMark>
                <GatsbyImage
                  image={getImage(props.image.localFile)}
                  alt={props.image.altText}
                  title={props.image.title}
                />
            </div>
            <MoreButton>
              <a href={props.button}>napisz do nas >></a>
              </MoreButton>
          </div>
      </div>
      <SmokeImage
            bottom='-110%'
            right='-38%'
          >
            <img src={SmokeBackground} alt="orange smoke" />
      </SmokeImage>
    </StyledConecptSummary>
  )
}

export default ConecptSummary
