import React, { useEffect, useState, useRef } from "react"
import Container from "../../container"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StyledConecptIntro} from "./StyledElements/StyledConceptIntro"



gsap.registerPlugin(ScrollTrigger);


const ConecptIntro = props => {


  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
      ScrollTrigger.matchMedia({
        "(max-width: 799px)": function() {
          revealRefs.current.forEach((el, index) => {
            gsap.fromTo(el, {
            }, {
              duration: 1.5,
              ease: 'none',
              scrollTrigger: {
                id: `section-${index+1}`,
                trigger: el,
                start: 'center-=100 center-=200',
                end: 'center+=100 center-=200',
                toggleActions: 'play none none reverse',
                toggleClass: {targets: el, className: "is-active"},
                scrub: true,
              }
            });

          });
        }
      })
      ScrollTrigger.matchMedia({
        "(min-width: 800px)": function() {
          revealRefs.current.forEach((el, index) => {
            gsap.fromTo(el, {
            }, {
              duration: 1.5,
              ease: 'none',
              scrollTrigger: {
                id: `section-${index+1}`,
                trigger: el,
                start: 'top-=200 center+=150',
                end: 'top-=150 center+=20',
                toggleActions: 'play none none reverse',
                toggleClass: {targets: el, className: "is-active"},
                scrub: true,
              }
            });
          });
        }
      })

  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  return (
    <StyledConecptIntro id="triggerElement">
        <Container>

            <ul id="headline">
            {props.data.map ((el, index) => {
              return (
                <li ref={addToRefs} key={index}>{el.line}</li>
              )
            })}
              {/* <li ref={addToRefs}>Chcesz zacząć zmiany</li>
              <li ref={addToRefs}>ale nie wiesz jak?</li>
              <li ref={addToRefs}>A może wchodzisz na </li>
              <li ref={addToRefs}>rynek z nowym pomysłem?</li>
              <li ref={addToRefs}>W obu przypadkach potrzebna </li>
              <li ref={addToRefs}>Ci koncepcja komunikacji, </li>
              <li ref={addToRefs}>od której będzie zależeć</li>
              <li ref={addToRefs}> Twój przyszły sukces.</li> */}

            </ul>
        </Container>
    </StyledConecptIntro>
  )
}

export default ConecptIntro
