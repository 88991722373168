import styled from "styled-components"

export const StyledBigTitle = styled.section`
    z-index: 0;
    position: relative;

    .bigTitle {
        display: flex;
        flex-direction: column;
        line-height: 1;
        text-transform: uppercase;
        font-size: clamp(80px,20vmin,250px);
        grid-gap: 10px;
        overflow: hidden;
        word-spacing: 20px;

        @media ${props => props.theme.sizes.laptop} {
            line-height: 166px;
            text-transform: uppercase;
            font-size: clamp(180px,24vmin,250px);
            grid-gap: 30px;
            word-spacing: 30px;
        }
        @media ${props => props.theme.sizes.desktop} {
            line-height: 206px;
            text-transform: uppercase;
            font-size: clamp(180px,28vmin,350px);
            grid-gap: 50px;
            word-spacing: 50px;
        }
    }
`
export const StyledFullTitle = styled.div`
    display: flex;
    flex-direction: row;
    grid-gap: 50px;
    width: max-content;
    transform: translateX(${props => props.transalteStart});
    animation: 25s infinite alternate ${props => props.keyFrame};
    animation-timing-function: linear;

    @media ${props => props.theme.sizes.laptop} {
        grid-gap: 100px;
    }

    @media ${props => props.theme.sizes.laptop} {
        animation: 35s infinite alternate ${props => props.keyFrame};
        animation-timing-function: linear;
    }

    @keyframes ${props => props.keyFrame} {
        from {
            transform: translateX(${props => props.transalteStart});
        }

        to {
            transform: translateX(${props => props.transalteEnd});
        }
    }

`
export const StyledTransparentTitle = styled(StyledFullTitle)`
    width: max-content;
    -webkit-text-fill-color: transparent;
    -moz-text-stroke-color: ${props => props.theme.colors.primary};
    -webkit-text-stroke-color: ${props => props.theme.colors.primary};
    -moz-text-stroke-width: 2px;
    -webkit-text-stroke-width: 2px;
`